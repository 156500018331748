.opening {
  margin-top: toRem(150);
  height: auto;
  @include media('>phone') {
    margin-top: 0;
  }
  @include media('>tablet') {
    height: 100vh;
  }  

  .wrapper {
    text-align: center;
    justify-content: center;
    align-items: center;
    @include media('>phone') {
      text-align: left;
      flex-direction: row;
    }
  }   

  .diagonal {
    height: toRem(300);
    @include media('>phone') {
      height: toRem(500);
    }
    @include media('>tablet') {
      height: toRem(550);
      height: 79vh;
    }   
    @include media('>desktop') {
      height: 100vh;
    }           
    
    img {
      width: 100%;
      height: auto;
      margin-top: -20%;
      @include media('>phone') {
        margin-top: -40%;
      }
    }
  }     

  .opening__text {
    width: toRem(300);
    margin-top: toRem(480);
    @include media('>phone') {
      margin: toRem(150) 0 0 0;
      padding-left: toRem(40);
      width: toRem(400);
      order: 1;
    }
    @include media('>tablet') {
      margin-top: toRem(150);
      margin-top: 20vh;
    }
    @include media('>desktop') {
      width: 40vw;
      padding-left: 7vw;        
    }                 
  }

  &--mirror {
    .opening__text {
      @include media('>phone') {
        padding-left: 0;
        padding-right: toRem(40);
        text-align: right;
      }
      @include media('>desktop') {
        width: 40vw;
        padding-right: 7vw;   
        text-align: right;
      }          
    }
  }

  p {
    width: 100%;
    margin-bottom: toRem(24);
    font-size: toRem(16); 
    line-height: 1.6;
    @include media('>phone') {
      font-size: toRem(20);
    }
    @include media('>tablet') {
      font-size: toRem(24);
    }    
    @include media('>desktop') {
      font-size: toRem(28);
    }   
    @include media('>desktop-xxl') {
      font-size: 1.5vw;
    }         
  }    

  .animation {
    @include media('>tablet') {
      height: 100%;
    }
  }

  &--1 {
    margin-top: toRem(240);

    @include media('>tablet') {
      margin-top: 15vh;
    }

    .diagonal {
      height: toRem(400);
      @include media('>phone') {
        height: toRem(400);
      }
      @include media('>tablet') {
        margin-top: 10vh;
        height: toRem(550);
        height: 90vh;
      }        
    }

    .animation {
      width: toRem(400);
      @include media('>phone') {
        width: toRem(450);
        order: 2;
      }   
      @include media('>tablet') {
        margin-top: toRem(180);      
        margin-top: 40vh;     
        width: 50vw; 
      }
      @include media('>desktop') {
        margin-top: 30vh;
        width: 55vw;
        margin-left: -5vw;
      }
      div {
        @include media('>desktop') {
          height: 70vh;
        }
      }  
      div:first-child {
        z-index: z('elevate3');
      }
      div:last-child {
        z-index: z('elevate3');
      }         
    }

    .rubble {
      display: none;
      @include media('>phone') {
        display: block;
      }
    
      > img {
        position: absolute;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .2s;
    
        &:nth-child(1) {
          top: toRem(24);
          left: toRem(-60);
          width: toRem(150);
          z-index: z('elevate2');
          @include media('>tablet') {
            top: 8vh;
            left: -2vw;
            width: 14vw;
          }
          @include media('>desktop') {
            top: 10vh;
          }            
          @include media('>desktop-xxl') {
            left: -5vw;
            top: 15vh;
          }
        }
        &:nth-child(2) {
          top: toRem(190);
          left: toRem(-130);
          width: toRem(150);  
          @include media('>tablet') {
            top: 45vh;
            left: -15vw;
            width: 12vw;
          }    
          @include media('>desktop') {
            top: 47vh;
            left: -10vw;
          }                 
          @include media('>desktop-xxl') {
            left: -10vw;
            top: 45vh;
          }                        
        }
        &:nth-child(3) {
          top: toRem(350);
          left: toRem(-50);
          width: toRem(180);
          z-index: z('elevate4');  
          @include media('>tablet') {
            top: auto;
            left: -1vw;
            bottom: -5vh;
            width: 18vw;
          }    
          @include media('>desktop') {
            left: 3vw;
            bottom: -7vh;
          }            
          @include media('>desktop-xxl') {
            left: -4vw;
            bottom: -5vh;
          }                                              
        }
        &:nth-child(4) {
          top: toRem(30);
          right: toRem(-30);
          width: toRem(100);  
          @include media('>tablet') {
            top: 12vh;
            right: 2vw;
            width: 9vw;
          }       
          @include media('>desktop-xxl') {
            top: 10vh;
            right: -5vw;
          }                          
        }
        &:nth-child(5) {
          top: toRem(170);
          right: toRem(-150);
          width: toRem(150);                     
          @include media('>tablet') {
            top: 35vh;
            right: -10vw;
            width: 13vw;
          }  
          @include media('>desktop-xxl') {
            right: -15vw;
          }                       
        }
        &:nth-child(6) {
          top: toRem(300);
          right: toRem(-100);
          width: toRem(220);  
          @include media('>tablet') {
            top: auto;
            bottom: 13vh;
            right: -5vw;
            width: 17vw;
          }   
          @include media('>desktop-xxl') {
            bottom: 17vh;
            right: -9vw;
          }                                             
        }                 
      }
    }
     
  }

  &--2 {

    @include media('>phone') {
      margin-top: toRem(300);
    }

    @include media('>tablet') {
      margin-top: 15vh;
      height: 85vh;
    }
  
    .diagonal {
      height: toRem(400);
      @include media('>phone') {
        display: none;
      } 
      img {
        transform: scaleY(-1);
      }
    }    

    .opening__text {
      margin-top: toRem(490);
      @include media('>phone') {
        margin-top: toRem(100);
      }
    }

    .animation {
      width: toRem(400);
      @include media('>phone') {
        margin-top: toRem(-60);
        width: toRem(550);
        order: 1;
      }   
      @include media('>tablet') {   
        margin-top: 30vh;  
        width: 50vw;    
      }      
      @include media('>desktop') {
        margin-top: 20vh;
        width: 50vw;
        margin-right: -5vw;
      }
      div {
        @include media('>desktop') {
          height: 70vh;
        }
        &:first-child{ z-index: z('elevate3') }
        &:last-child{ z-index: z('elevate2')}
      }     
    }

    .rubble {
      display: none;
      @include media('>phone') {
        display: block;
      }
  
      > img {
        position: absolute;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .5s;
    
        &:nth-child(1) {
          top: toRem(130);
          left: toRem(-70);
          width: toRem(70);
          @include media('>tablet') {
            width: 6vw;
            left: -5vw;
          }
          @include media('>desktop') {
            top: auto;
            bottom: 23vh;
            left: 1vw;
          }          
          @include media('>desktop-xxl') {
            top: auto;
            bottom: 25vh;
            left: -6vw;
          }
        }
        &:nth-child(2) {
          top: toRem(300);
          left: toRem(-70);
          width: toRem(120);  
          z-index: z('elevate3');    
          @include media('>tablet') {
            width: 12vw;
            left: -9vw;
            top: auto;
            bottom: -10vh;
          }  
          @include media('>desktop') {
            left: -2vw;
            bottom: -12vh;
          }  
          @include media('>desktop-xxl') {
            left: -6vw;
            bottom: -12vh;
          }                                
        }
        &:nth-child(3) {
          top: toRem(400);
          left: toRem(140);
          width: toRem(50);  
          z-index: z('elevate3');  
          @include media('>tablet') {
            width: 5vw;
            top: auto;
            bottom: -17vh;
            left: 10vw;
          }    
          @include media('>desktop') {
            left: 16vw;
            bottom: -3vh;
          }   
          @include media('>desktop-xxl') {
            left: 8vw;
            bottom: 4vh;
          }                                               
        }
        &:nth-child(4) {
          top: toRem(20);
          right: toRem(-100);
          width: toRem(100);    
          @include media('>tablet') {
            width: 9vw;
            top: 15vh;
            right: -8vw;
          }       
          @include media('>desktop') {
            top: 12vh;
            right: -5vw;
          }   
          @include media('>desktop-xxl') {
            top: 14vh;
            right: -9vw;
          }                                                      
        } 
        &:nth-child(5) {
          top: toRem(340);
          right:toRem(-30);
          width: toRem(320);
          z-index: z('elevate4');    
          @include media('>tablet') {
            width: 25vw;
            top: auto;
            bottom: -10vh;
            right: -1vw;
          }    
          @include media('>desktop') {
            bottom: -10vh;
            right: 3vw;
          }   
          @include media('>desktop-xxl') {
            bottom: -8vh;
            right: -1vw;
          }                                         
        }
        &:nth-child(6) {
          top: toRem(310);
          right: toRem(-80);  
          width: toRem(70); 
          z-index: z('elevate3');    
          @include media('>tablet') {
            width: 6vw;
            top: auto;
            bottom: 15vh;
            right: -5vw;
          }  
          @include media('>desktop') {
            bottom: 13vh;
            right: -2vw;
          }   
          @include media('>desktop-xxl') {
            bottom: 12vh;
            right: -7vw;
          }                                      
        }
      }
    }  

  }  

  &--3 {

    @include media('>phone') {
      margin-top: toRem(100);
    }

    @include media('>tablet') {
      margin-top: 32vh;
    }

    .diagonal {
      height: toRem(400);
      @include media('>phone') {
        height: toRem(450);
        margin-top: toRem(100);
      }
      @include media('>tablet') {
        margin-top: 10vh;
        height: toRem(550);
        height: 90vh;
      }         
    }    

    .opening__text {
      margin-top: toRem(520);
      @include media('>phone') {
        margin-top: toRem(170);
      }   
    }

    .animation {
      width: toRem(400);
      z-index: z('elevate3');
      @include media('>phone') {
        margin-top: toRem(100);
        width: toRem(500);
        order: 2;
      }     
      @include media('>tablet') {    
        margin-top: 10vh;  
        width: 45vw;    
        margin-left: -5vw;
      }    
      @include media('>desktop') {
        margin-top: 50vh;
        transform: scale(1.5);
      }
      @include media('>desktop-xxl') {
        margin-left: -1vw;
      }
      div {
        @include media('>desktop') {
          height: 70vh;
        }
      }              
    }

    .rubble {
      display: none;
      @include media('>phone') {
        display: block;
      }
    
      > img {
        position: absolute;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .2s;
    
        &:nth-child(1) {
          top: toRem(100);
          left: toRem(-30);
          width: toRem(120);
          z-index: z('elevate2');    
          @include media('>tablet') { 
            width: 10vw;
            top: 40vh;
            left: -8vw;
          }   
          @include media('>desktop') {
            top: 23vh;
            left: 2vw;
            width: 11vw;
          }   
          @include media('>desktop-xxl') {
            top: 23vh;
            left: -2vw;
            width: 11vw;
          }                               
        }
        &:nth-child(2) {
          top: toRem(370);
          left: toRem(-70);
          width: toRem(220);
          z-index: z('elevate2');    
          @include media('>tablet') { 
            width: 17vw;
            top: auto;
            bottom: 5vh;
            left: -10vw;
          }    
          @include media('>desktop') {
            bottom: -3vh;
            left: -2vw;
            width: 18vw;
          }   
          @include media('>desktop-xxl') {
            bottom: 2vh;
            left: -7vw;
            width: 18vw;
          }                                    
        }   
        &:nth-child(3) {
          top: toRem(250);
          right: toRem(10);
          width: toRem(150);
          z-index: z('elevate1');      
          @include media('>tablet') { 
            width: 17vw;
            top: auto;
            bottom: 20vh;
            right: -2vw;
          }   
          @include media('>desktop') { 
            right: 2vw;
          } 
          @include media('>desktop-xxl') {
            right: -7vw;
            width: 15vw;
          }                                               
        }                   
      }
    } 

  }   

}

.text-typing .char,
.text-typing--short .char {
  visibility: hidden;
}

.text-typing.active .char {
  animation: animate 0s steps(2) forwards;
  animation-delay: calc(0.03s * var(--char-index));
}

.text-typing--short.active .char {
  animation: animate 0s steps(2) forwards;
  animation-delay: calc(0.05s * var(--char-index));
}

@keyframes animate {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
