// Text Colors
$color-text-base:     #3D3D3D;
$color-text-heading:  #141414;
$color-text-fade:     #B6B6B6;
$color-text-link:     #3A5FD6;

// Accent Colors
$color-brand-primary:   #E31E52;
$color-brand-secondary: #3A5FD6;
$color-accent-blue:     #EDF3F7;
$color-border:          #D6D6D6;

// Fonts Deprecated
$font-bs-display: 'Buka Sans Display';
$font-bs-text:    'Buka Sans Text';

// Fonts
$font-buka-display: 'Buka Sans Display';
$font-buka-text:    'Buka Sans Text';

// Font Size
$font-size-baseline: 16px;

// Easings
$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);

// Layering
$z-indexes: (
  'top',
  'share',
  'nav',
  'elevate4',
  'elevate3',
  'elevate2',
  'elevate1',
  'base',
);
