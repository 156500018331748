.preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  z-index: z('top');
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  transition: all .6s $ease-in-out;

  &.fade {
    opacity: 0;
  }

  &.remove {
    display: none;
  }  

  &__box {
    position: absolute;
    bottom: toRem(100);
  }

  &__hero {
    width: toRem(120);
    transition: all .3s $ease-in-out;
    @include media('>phone') {
      width: toRem(200);
    }    
  }

  &__title {
    margin-top: toRem(60);
    margin-bottom: toRem(16);

    @include media('>phone') {
      font-size: toRem(24);
    }
  }

  .spinner {
    margin: 0 auto;
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 35px;
    height: 35px;
  
    & .path {
      stroke: #e21e52;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  
}