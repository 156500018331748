.wrapper {
  display: flex;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  @include media('>phone') {
    width: 720px;
    padding-left: auto;
    padding-right: auto;    
  }
  @include media('>tablet') {
    width: 960px;
    padding-left: auto;
    padding-right: auto;  
  }
  @include media('>desktop') {
    width: 1200px;
    padding-left: auto;
    padding-right: auto;  
  }
  @include media('>desktop-xl') {
    width: 1400px;
    padding-left: auto;
    padding-right: auto;   
  }  
}