.closing {
  height: auto;
  @include media('>tablet') {
    height: 100vh;
  }

  .wrapper {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  h2 {
    margin: 0 auto toRem(12);
    max-width: toRem(300);    
    font-size: toRem(24);
    line-height: 1.2;  
    width: auto;
    @include media('>phone') {
      font-size: toRem(24);
      max-width: 100%;   
    }
    @include media('>tablet') {
      font-size: toRem(32);
      width: 50vw;
      margin-bottom: toRem(32);
    }     
    @include media('>desktop') {
      font-size: toRem(40);
      width: 50vw;
    }   
    @include media('>desktop-xxl') {      
      width: 30vw;
    }
  }

  p {
    margin: 0 auto toRem(24);
    max-width: toRem(300);  
    font-size: toRem(16); 
    line-height: 1.6;
    @include media('>phone') {
      font-size: toRem(20);
      max-width: 100%;  
    }
    @include media('>tablet') {
      font-size: toRem(24);
    } 
    @include media('>desktop') {
      font-size: toRem(28);
      width: 60vw;
    }     
    @include media('>desktop-xxl') {      
      width: 40vw;
    }                  
  } 
  
  &--1 {
    margin-top: toRem(100);
    height: toRem(600);
    @include media('>phone') {
      height: toRem(650);
    }
    @include media('>tablet') {
      margin-top: 0;
      height: 100vh;
    }    
    @include media('>desktop') {
      height: 100vh;
    } 

    .diagonal {
      height: toRem(500);
      @include media('>phone') {
        height: toRem(600);
      }
      @include media('>tablet') {
        margin-top: toRem(100);
      }      
      @include media('>desktop') {
        height: 90vh;
      }
      img {
        width: 100%;
        height: auto;
        margin-top: -20%;
        @include media('>phone') {
          margin-top: -20%;
        }
      }
    }

    .animation {
      margin-top: toRem(60);
      max-width: toRem(300);
      max-height: toRem(100);
      transform: scale(2);
      @include media('>phone') {
        margin-top: 0;
        width: 100%;
        max-width: 100%;      
        transform: scale(1);
      }
    }
  }

  &--2 {
    margin-top: toRem(100);
    height: toRem(400);
    @include media('>tablet') {
      margin-top: toRem(100);
      height: 100vh;
    }
    @include media('>desktop') {
      margin-top: 40vh;
      height: 100vh;
    }  
    @include media('>desktop-xxl') {
      margin-top: 10vh;
    }

    .animation {
      margin-top: toRem(80);
      // width: 100%;
      max-width: toRem(300);
      max-height: toRem(100);      
      transform: scale(2);
      @include media('>phone') {
        margin-top: toRem(30);
        width: 100%;
        max-width: 100%;      
        transform: scale(1);
      }
    }
  }

}
