.community {
  margin: toRem(48) auto;

  .wrapper {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  p {
    margin: 0 auto;
    max-width: toRem(300);    
    font-size: toRem(16); 
    line-height: 1.6;
    @include media('>phone') {
      font-size: toRem(20);
      max-width: 100%;  
    }
    @include media('>tablet') {
      font-size: toRem(24);
    }       
    @include media('>desktop') {
      font-size: toRem(28);
      width: 60vw;
    }           
  }

  h2 {
    margin: toRem(32) auto 0;
    max-width: toRem(300);    
    font-size: toRem(24); 
    line-height: 1.2;    
    @include media('>phone') {
      font-size: toRem(28);
      max-width: 100%; 
    }
    @include media('>desktop') {
      font-size: toRem(40);  
    }         
  }

  .diagonal {
    margin-top: 0;
    height: toRem(400);  

    @include media('>phone') {
      display: none;    
    }
    
    img {
      width: 100%;
      height: auto;
      margin-top: 0;
    }
  }    

  .community__box {
    margin: toRem(32) auto;
    display: flex;
    flex-direction: column;
    @include media('>phone') {
      flex-direction: row;
      margin: toRem(32) auto;
    }

    &__item {
      margin: 0 toRem(40) toRem(48);

      &:last-child {
        margin-bottom: 0;
      }

      &__text {
        text-align: center;

        h3 {
          font-size: toRem(60);   
          font-weight: 700;
          letter-spacing: -1px;
          line-height: 1.2;   
          @include media('>phone') {
            font-size: toRem(70);
          }
          @include media('>desktop') {
            font-size: 7vw;
          }                    
        }

        p {
          font-family: $font-bs-display;
          font-size: toRem(20);
          line-height: 1.2;    
          text-transform: uppercase;   
          width: auto;     
        }
      }
    }
  }

}
