.diagonal {
  position: absolute;
  width: 100%;
  transform: skewY(-15deg);
  overflow: hidden;

  &--mirror {
    transform: skewY(15deg);
  }

  img {
    background-size: cover;
  }  

}