.nav {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  // width: 100%;
  height: 52px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255,0.72);
  transition: all .3s $ease-in-out;
  z-index: z('nav');
  @include media('>phone') {
    height: 76px;
  }

  &.hide {
    top: -52px;
    @include media('>phone') {
      top: -76px;
    }    
  }

  .wrapper {
    position: relative;
    justify-content: space-between;
    align-items: center;    
  }

  ul li {
    display: flex;
    align-items: baseline;
    font-size: 1rem;
    color: $color-text-base;

    p {
      display: flex;
      align-items: baseline;

      @include media('>desktop') {
        font-size: toRem(18);
      }

      &:last-child {
        margin-left: toRem(20);

        a {
          margin: 0 2px;
          color: $color-text-fade;   

          &.selected {
            font-family: $font-bs-display;
            font-weight: 700;
            color: $color-text-link;          
          }
        }        
      }
    }
  }

  svg {
    display: block;
    width: toRem(88);
    height: auto;
    transform: scale(1);
    @include media('>desktop') {
      transform: scale(1.2);
    }
  }
}
