.long {
  margin-top: toRem(350);
  height: auto;
  @include media('>desktop') {
    margin-top: toRem(550);
  }


  .wrapper {
    position: relative;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    max-width: toRem(300);
    @include media('>phone') {
      max-width: 100%;
    }
  }

  h2 {
    margin-bottom: toRem(24);
    font-size: toRem(24);
    line-height: 1.2;  
  
    @include media('>phone') {
      font-size: toRem(24);
      margin-bottom: toRem(32);
    }
    @include media('>tablet') {
      font-size: toRem(32);
      width: 30vw;
      margin: 0 auto toRem(32);
    }        
    @include media('>desktop') {
      font-size: toRem(40);
      margin-bottom: 5vh;
      width: 30vw;
    }        
  }

  p, a {
    width: 100%;
    margin-bottom: toRem(24);
    font-size: toRem(16); 
    line-height: 1.6;
    @include media('>phone') {
      font-size: toRem(20);
      margin-bottom: toRem(28);
    }
    @include media('>tablet') {
      font-size: toRem(24);
    }       
    @include media('>desktop') {
      font-size: toRem(28);
    }   
       
  } 

  a {
    color: #2B4AC7;
    font-family: $font-bs-display;
    font-weight: 500;
  }

  &__more {
    margin-top: 24px;
    position: relative;
    z-index: 11;
    width: 100%;
    text-align: center;
  }

  &__fold {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: toRem(200);
    overflow: hidden;

    @include media('>tablet') {
      margin: 0 auto;
      width: 70%;
      height: toRem(300);
    }    

    img.ic-bukatabungan {
      max-width: 70%;
      @include media('>tablet') {
        width: 40%;
      }
    }

    img {
      margin: 0 auto;
      max-width: 100%;
      margin-bottom: 8px;
      @include media('>tablet') {
        width: 40%;
      }
    }

    .chart-mweb {
      display: block;
      @include media('>phone') {
        display: none;
      }
    }

    .chart-web {
      width: 100%;
      display: none;
      @include media('>phone') {
        display: block;
      }
    }

    &.selected {
      &:before {
        display: none;
      }      
    }

    &:before {
      position: absolute;
      display: block;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      height: 100px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
  }

  .rubble1 {
    position: absolute;
    top: toRem(300);
    left: 0;
    right: 0;

    img {
      position: absolute;

      &:nth-child(1) {
        top: 29rem;
        left: -9.5rem;
        width: 3rem;
      }
      &:nth-child(2) {
        top: 44.375rem;
        left: -7.4375rem;
        width: 3.625rem;    
      }
      &:nth-child(3) {
        top: 24.375rem;
        right: -9.0625rem;
        width: 5.75rem;   
      }
      &:nth-child(4) {
        top: 54.6875rem;
        right: -4.875rem;
        width: 2.5rem;
      }             
    }
  }

  .rubble3 {
    position: absolute;
    top: toRem(5500);
    left: 0;
    right: 0;

    img {
      position: absolute;

      &:nth-child(1) {
        top: 37rem;
        left: -7.9375rem;
        width: 2rem;
      }
      &:nth-child(2) {
        top: 4.875rem;
        left: -6.5625rem;
        width: 2.625rem;    
      }
      &:nth-child(3) {
        top: 32.375rem;
        right: -7.9375rem;
        width: 4rem;   
      }
      &:nth-child(4) {
        top: 6.6875rem;
        right: -8.5625rem;
        width: 3.25rem;     
      }             
    }
  }  

  .rubble4 {
    position: absolute;
    top: toRem(7000);
    left: 0;
    right: 0;

    img {
      position: absolute;

      &:nth-child(1) {
        top: 14rem;
        left: -7.9375rem;;
        width: 4rem;
      }
      &:nth-child(2) {
        top: 37.875rem;
        left: -9.5625rem;
        width: 4.625rem;   
      }
      &:nth-child(3) {
        top: 19.375rem;
        right: -6.9375rem;
        width: 3rem;      
      }
      &:nth-child(4) {
        top: 29.6875rem;
        right: -9.5625rem;
        width: 4.25rem;     
      }             
    }
  }   

  .rubble5 {
    position: absolute;
    top: toRem(3800);
    left: 0;
    right: 0;

    img {
      position: absolute;

      &:nth-child(1) {
        top: 0rem;
        left: -9.1875rem;
        width: 5rem;
      }
      &:nth-child(2) {
        top: 31.875rem;
        left: -7.5625rem;
        width: 3.625rem;      
      }
      &:nth-child(3) {
        top: 26.375rem;
        right: -6.75rem;
        width: 4rem;      
      }
      &:nth-child(4) {
        top: 5.6875rem;
        right: -7.3125rem;
        width: 2.25rem;      
      }             
    }
  }   


}

.load__more-back-top {
  display: none;
  transform: rotate(180deg);
}
