.content {
  position: relative;
  display: flex;
  justify-content: center;
  
  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }
}