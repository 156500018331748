@font-face {
    font-family: $font-buka-text;
    src: url('../font/BukaSansText-Regular.woff2') format('woff2'),
        url('../font/BukaSansText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $font-buka-display;
    src: url('../font/BukaSansDisplay-Medium.woff2') format('woff2'),
        url('../font/BukaSansDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: $font-buka-display;
    src: url('../font/BukaSansDisplay-Bold.woff2') format('woff2'),
        url('../font/BukaSansDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }