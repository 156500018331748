.hero {
  height: 100vh;
  align-items: space-between;
  
  .wrapper {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: toRem(-120);
    z-index: z('base');
    @include media('>phone') {
      margin-top: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;      
    }
  }

  &__logo {
    margin-bottom: toRem(32);
    width: toRem(180);
    order: 1;
    transition: all .6s ease-in-out;
    &.anim {
      transform: scale(.8) rotate(25deg);
    }
    @include media('>phone') {
      order: 2;
      margin-bottom: 0;
      width: toRem(340);
    }
    @include media('>tablet') {
      width: toRem(400);
    }    
    @include media('>desktop') {
      width: 38vw;
    }
    img { width: 100% }
  }

  &__text {
    order: 2;
    // opacity: 0;
    @include media('>phone') {
      order: 1;
      text-align: right;
      width: toRem(400);
      padding-right: toRem(24);
    }   
    @include media('>tablet') {
      width: toRem(500);
      padding-right: toRem(24);
    }       
    @include media('>desktop') {
      width: 50vw;
      padding-right: 4vw;
    }    

    h1 { 
      font-size: toRem(28);
      line-height: 1.2;
      @include media('>phone') {
        font-size: toRem(40);  
      }
      @include media('>tablet') {
        font-size: toRem(50);  
      }      
      @include media('>desktop') {
        font-size: toRem(70);
        font-size: 5vw;    
      }      
    }    
    
    h3 { 
      margin-bottom: toRem(4);
      font-size: toRem(16);
      @include media('>phone') {
        font-size: toRem(20);
      }
      @include media('>tablet') {
        font-size: toRem(24);  
      }         
      @include media('>desktop') {
        font-size: toRem(24);
      }   
      @include media('>desktop-xxl') {
        font-size: toRem(28);
      }   
    }

    svg {
      width: toRem(42);
      height: toRem(11.5);
      fill: $color-text-base;
      @include media('>phone') {  
        margin-left: 6px;
        margin-right: 6px;     
        transform: scale(1.2);
      }
      @include media('>tablet') {  
        width: toRem(42);
        height: toRem(13);        
        margin-left: 6px;
        margin-right: 6px;     
        transform: scale(1.4);
      }      
      @include media('>desktop') {
        width: toRem(40);
        height: toRem(13);      
        margin-left: 10px;
        margin-right: 10px;     
        transform: scale(1.5);
      }   
      @include media('>desktop-xxl') {
        transform: scale(1.7);
        width: toRem(40);
        height: toRem(14); 
        margin-left: 16px;
        margin-right: 16px;          
      }               
    }
  }

  &__notes {
    position: absolute;
    bottom: toRem(120);
    @include media('>phone') {
      bottom: toRem(32);
    }

    p {
      font-size: toRem(14);
      margin-bottom: toRem(12);
      @include media('>phone') {  
        font-size: toRem(16);
      }
      @include media('>tablet') {
        font-size: toRem(18);
        margin-bottom: toRem(32);
      }
      @include media('>desktop') {
        font-size: toRem(20);
        margin-bottom: toRem(32);
      }      
    }

    svg{
      @include media('>tablet') {
        width: toRem(32);
        height: toRem(32);
        transform: scale(1.5);
      }      
    }
    
  }

}