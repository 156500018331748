.animation {
  position: relative;
  width: 100%;
  height: 100%;

  img, svg {
    width: 100%;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;    
  }
  
}