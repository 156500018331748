*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

html {
  font-size: $font-size-baseline;
  line-height: 1;
}

body {
  font-family: $font-bs-text;
  color: $color-text-base;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

p {
  font-size: toRem(16);
}

p b {
  font-family: $font-buka-display;
  font-weight: 700;
}

.icon-svg-sprite { 
  display: none;
}

.icon-svg { 
  display: inline;
  vertical-align: baseline;
}

.icon-svg-body-text {
  display: inline-block;
  width: toRem(40);
  height: toRem(11.5);
  fill: $color-text-base;

  @include media('>phone') {  
    margin: 0 toRem(6);
    height: toRem(13);
    transform: scale(1.25);
  }
  @include media('>tablet') {
    margin: 0 toRem(12);
    height: toRem(14);
    transform: scale(1.5);    
  }  
  @include media('>desktop') {
    margin: 0 toRem(15);
    height: toRem(14.5);
    transform: scale(1.6);    
  }       
}

.icon-svg-h2-text {
  display: inline-block;
  margin: 0 toRem(7);
  width: toRem(38);
  height: toRem(13);
  fill: $color-text-base;
  transform: scale(1.4);

  @include media('>phone') {  
    margin: 0 toRem(12);
    width: toRem(38);
    height: toRem(14);    
    transform: scale(1.5);
  }  
  @include media('>tablet') {
    margin: 0 toRem(16);
    width: toRem(38);
    height: toRem(15);    
    transform: scale(1.8);    
  }
  @include media('>desktop') {
    margin: 0 toRem(24);
    width: toRem(38);
    height: toRem(16);    
    transform: scale(2.2);    
  }  
}

.icon-svg-h3-text {
  display: inline-block;
  margin: 0 toRem(4);
  width: toRem(38);
  height: toRem(13);
  fill: $color-text-base;
  transform: scale(1.15);

  @include media('>tablet') {
    width: toRem(42);
    height: toRem(13);     
    margin: 0 toRem(10);     
    transform: scale(1.4);
  }
}
