.footer {
  position: relative;
  padding: 40px 0;
  background: #FFFFFF;
  display: block;
  justify-content: center;  

  .wrapper {
    display: flex;
    flex-direction: column;   
    @include media('>phone') {  
      margin: 0 auto;
    } 
  }

  &__top {
    display: none;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include media('>phone') {  
      display: flex;
    }

    &__item {

      &:last-child {
        flex-basis: 150px;
        @include media('>tablet') {  
          flex-basis: 310px;
        }        
      }

      h4 {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 1.4;
      }
      ul li {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 1.4;

        img {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
      .button {
        display: block;
        width: 240px;
        padding: 12px 24px;
        border-radius: 4px;
        border: 2px solid #404040;
        font-family: $font-bs-display;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  &__bottom {

    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    @include media('>phone') { 
      border-top: 1px solid #d6d6d6;
      margin-top: 40px;
      padding: 40px 0 0;
      justify-content: space-between;  
      flex-direction: row;
      text-align: left;  
      align-items: center;
    }

    p {
      font-family: $font-bs-display;
      font-weight: 700;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      margin-bottom: toRem(18);
      @include media('>phone') { 
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
      }
      &:first-child {
        @include media('>phone') { 
          margin-right: toRem(40);
        }
      }

      img {
        display: inline-block;
        vertical-align: middle;
      }

      a {
        margin-bottom: toRem(18);
        @include media('>phone') { 
          margin-left: 24px;
          margin-bottom: 0;
        }
      }
    }
  }

  &__app {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    a:first-child {
      @include media('>phone') { 
        margin-left: 40px;
      }        
    }

    a:last-child {
      margin-left: 8px;

    }    
  }
}

.footer-upper {
  position: relative;
  display:block;
  width:100%;
  margin-top:100px;
  text-align: center;
  background:#EDF3F7;
  font-size: toRem(16);
  line-height: 1.2;
  padding: toRem(30) toRem(12);
  @include media('>phone') {
    font-size: toRem(20);
  }
  @include media('>tablet') {
    font-size: toRem(24);
  }    

  a {
    color:#3A5FD6;
    font-family: $font-bs-display;
    font-weight: 700;
  } 
}
