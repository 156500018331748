.family {
  margin: toRem(100) auto;
  height: auto;
  @include media('>phone') {
    margin: toRem(100) auto;
  }
  @include media('>tablet') {
    margin-top: toRem(150)
  }

  .wrapper {
    justify-content: center;
    text-align: center;
  }

  p {
    margin: 0 auto;
    max-width: toRem(300);
    font-size: toRem(16);
    line-height: 1.6;
    @include media('>phone') {
      font-size: toRem(20);
      max-width: 100%;
    }
    @include media('>tablet') {
      font-size: toRem(24);
    }
    @include media('>desktop') {
      font-size: toRem(28);
    }
  }

  h2 {
    margin: 0 auto;
    max-width: toRem(300);
    margin-top: toRem(32);
    font-size: toRem(24);
    line-height: 1.2;
    @include media('>phone') {
      font-size: toRem(28);
      max-width: 100%;
    }
    @include media('>tablet') {
      font-size: toRem(32);
    }
    @include media('>desktop') {
      font-size: toRem(40);
    }
  }

  ul {
    max-width: toRem(300);
    margin: toRem(32) auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include media('>phone') {
      max-width: 100%;
    }
    @include media('>tablet') {
      margin: toRem(32) auto;
    }

    li {
      margin-bottom: 0;
      width: 50%;
      @include media('>phone') {
        width: 25%;
      }
      img {
        width: 100%;
      }
    }
  }

  .diagonal {
    margin-top: 0;
    height: toRem(400);
    @include media('>phone') {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
      margin-top: 0;
    }
  }

}
