.share {
  position: absolute;
  padding: toRem(16);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  right: 4px;
  top: 53px;
  background-color: #ffffff;
  z-index: z('share');
  width: toRem(280);
  border-radius: toRem(4);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.32);

  @include media('>phone') {
    top: 73px;
  }


  &__top {
    margin-bottom: toRem(16);
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: toRem(20);
    }
  }

  &__list {
    width: 100%;
    li {
      width: 100%;
      margin-bottom: toRem(16);
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: $font-bs-display;
      font-weight: 700;
      font-size: toRem(14);

      .share__img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: toRem(16);
        border-radius: 4px;
        width: 40px;
        height: 40px;
        border: 1px solid #C0C7D1;
      }

      p {
        margin-left: 0 !important;
      }
    }    
  }
}