h1 { 
  font-size: toRem(28);
  font-size: 9vw;
  @include media('>phone') {
    font-size: toRem(45);
    font-size: 6vw;    
  }
}
h2 { font-size: toRem(24) }
h3 { font-size: toRem(16) }
h4 { font-size: toRem(12) }
h5 { font-size: toRem(12) }
h6 { font-size: toRem(12) }

h1, h2, h3, h4, h5, h6 {
  font-family: $font-buka-display;
  font-weight: 700;
}